import React, { memo, useCallback, useRef, useMemo, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Webcam from "react-webcam";

import request from "../../api/request";

const CaptureImage = ({ handleClose, setValue, field, watch }) => {
    const value = watch(field);
    const [image, setImage] = useState(null);
    const webcamRef = useRef(null);
    const capture = useCallback(() => {
        const imageSrc = webcamRef.current.getScreenshot();
        setImage(imageSrc);
    }, []);

    const handleSubmit = useCallback(async () => {
        try {
            const data = await request('/safetipinapi/upload', 'POST', { image });
            setValue(field, value ? `${value},${data.image}` : data.image);
        } catch (error) {
            const name = image.replace("data:image/jpeg;base64,", "");
            setValue(field, value ? `${value},${name}` : name);
        }
        handleClose();
    }, [image, handleClose, setValue, field, value]);

    const videoConstraints = useMemo(
        () => ({
            facingMode: "environment",
            width: window.screen.width - 60,
            height: window.screen.height - 350,
        }),
        []
    );

    return (
        <Modal
            show
            onHide={handleClose}
            size="xl"
            dialogClassName="full-width-modal"
            animation={false}
        >
            <Modal.Header closeButton />
            <Modal.Body>
                {image ? (
                    <div className="text-center mb-2">
                        <img className="img-fluid" src={image} alt="capture" />
                    </div>
                ) : (
                    <Webcam
                        audio={false}
                        height={videoConstraints.height}
                        ref={webcamRef}
                        screenshotFormat="image/jpeg"
                        width={videoConstraints.width}
                        videoConstraints={videoConstraints}
                        screenshotQuality={1}
                        minScreenshotHeight={720}
                        minScreenshotWidth={1280}
                    />
                )}
                <div className="text-center">
                    {image ? (
                        <>
                            <button
                                className="btn btn-outline-dark mr-5"
                                onClick={() => setImage(null)}
                            >
                                Retake photo
                            </button>
                            <button className="btn btn-primary" onClick={handleSubmit}>
                                Submit
                            </button>
                        </>
                    ) : (
                        <button className="btn btn-primary" onClick={capture}>
                            Capture photo
                        </button>
                    )}
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default memo(CaptureImage);
