import React, { memo } from "react";
import { useController } from "react-hook-form";

const OtherComponent = ({ baseName, className, required }) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    name: `${baseName}.other`,
    rules: {
      required: required ? "Please enter your answer" : false,
    },
    shouldUnregister: true,
  });

  return (
    <>
      <input
        {...field}
        type="text"
        className={className}
        placeholder="Your Answer"
      />
      {error && <div className="text-danger p-2">{error.message}</div>}
    </>
  );
};

export default memo(OtherComponent);
