import React, { memo } from "react";
import { useController } from "react-hook-form";
import cn from "classnames";
import useEvent from "../../../hooks/useEvent";
import OtherComponent from "./OtherComponent";
import UploadButton from "./UploadButton";
import ImageView from "../ImageView";

const Checkboxes = ({
  question,
  index,
  compulsary,
  value: choicesString,
  photo_required,
  field,
  type,
}) => {
  const baseName = `${field}[${index}]`;
  const required = compulsary === "1";
  const {
    field: { onChange, value = '' },
    fieldState: { error },
  } = useController({
    name: `${baseName}.answer`,
    rules: {
        required: required ? "Please select any options" : false,
    },
  });
  const choices = choicesString.split(";");
  const isUploadVisible = photo_required === "1";
  const isSingle = type === "Checkboxes";

  const handleChange = useEvent((event) => {
    const newValue = event.target.value;
    const oldValues = value.split(",");
    onChange(
      oldValues.includes(newValue)
        ? oldValues.filter((v) => v !== newValue).join(",")
        : [...oldValues, newValue].join(",")
    );
  });

  return (
    <div className="formdiv">
      <div className="headingadj">
        <div style={{ width: 25, float: "left" }}>
          <b className="num_heading">{index + 1}- </b>
        </div>
        <div style={{ width: "90%", float: "left" }}>
          {question}
          {required && <span className="text-danger ml-2">*</span>}
        </div>
      </div>
      <div className="adjust">
        {choices.map((choice) => (
          <label className={cn("fieldadj1", isSingle  ? 'col1' : 'col21')} key={choice}>
            <div className="div11st">
              <input
                type="checkbox"
                className="radioadj ckbox"
                value={choice}
                onChange={handleChange}
                checked={value.includes(choice)}
              />
            </div>
            <div className="div22nd">
              {choice}
              {value.includes("Other") && choice.startsWith("Other") && (
                <OtherComponent
                  className="inputadjs cb_other"
                  baseName={baseName}
                  required={required}
                />
              )}
            </div>
          </label>
        ))}
        {error && <div className="text-danger float-left w-100 p-2">{error.message}</div>}
        {isUploadVisible && (
          <p className="fieldadj float-left w-100">
            <UploadButton baseName={baseName} />
          </p>
        )}
      </div>
      <ImageView name={`${baseName}.image`} />
    </div>
  );
};

export default memo(Checkboxes);
