import { useCallback, useLayoutEffect, useRef } from "react";

const useEvent = handler => {
    const handlerRef = useRef(handler);

    useLayoutEffect(() => {
        handlerRef.current = handler;
    });

    return useCallback((...args) => handlerRef.current(...args), []);
}

export default useEvent;
