import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import request from "../../api/request";

const List = () => {
  const navigate = useNavigate();
  const [list, setList] = useState([]);

  useEffect(() => {
    const userid = localStorage.getItem("userid");
    if (!userid) {
      navigate("../");
    }

    request(
      `/form/formlist?${new URLSearchParams({
        user_id: userid,
      }).toString()}`
    ).then(({ form }) => setList(form));
  }, [navigate]);

  const handleLogout = () => {
    localStorage.removeItem("userid");
    navigate("/userlogin");
  };

  return (
    <div className="wrapper">
      <div className="wrapper_inner">
        <div className="container" style={{ minHeight: "100vh" }}>
          <div className="row">
            <div className="col-md-4 offset-md-4 col-lg-6 offset-lg-3">
              <div className="row">
                <div className="col-6">
                  <img
                    src="/img/SafetiPin-Logo-1.png"
                    alt="logo"
                    style={{ width: 75 }}
                  />
                </div>
                <div className="col-6 text-right">
                  <button className="btnclass" onClick={handleLogout}>
                    Logout
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="default_template_holder clearfix">
            <div className="contact_detail">
              <div className="clearfix">
                <div className="row">
                  <div className="col-md-4 offset-md-4 col-lg-6 offset-lg-3">
                    <div className="table-responsive" style={{ paddingTop: 0 }}>
                      <table className="table table-hover">
                        <tbody>
                          {list.map(({ form_id, frm_name }) => (
                            <tr key={form_id}>
                              <td>
                                <Link to={`/userlogin/${form_id}`}>
                                  {frm_name}
                                </Link>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default List;
