import React, { memo, useMemo, useCallback, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";

import QuestionsFields from "./QuestionsFields";
import StoreLocalValue from "./StoreLocalValue";
import request from "../../api/request";
import { useModalActions } from "./ModalActionsContext";

const getLocalData = (defaultData, questionsData) => {
    const { form_id } = defaultData;
    const formData = localStorage.getItem("saftipin-form-data");
    const data = JSON.parse(formData) || {};

    if (data.form_id === form_id && questionsData.length) {
        return {
            ...data,
            data: questionsData,
        };
    }

    return defaultData;
};

const QuestionsForm = ({ id, questions, userid, questionsData }) => {
    const { setIsLoading } = useModalActions();
    const defaultData = useMemo(
        () => ({
            data: questions.map((question) => ({
                question_id: question.question_id,
                answer: "",
                other: "",
                imgcord: "",
                direction: "",
                image: "",
            })),
            form_id: id,
            userid,
            submit_datetime: new Date().toLocaleString(),
            latitude: "",
            longitude: "",
        }),
        [questions, id, userid]
    );
    const defaultValues = useMemo(
        () => getLocalData(defaultData, questionsData),
        [defaultData, questionsData],
    );

    const methods = useForm({
        defaultValues,
    });
    const { reset, handleSubmit, setValue, watch } = methods;

    const [latitude, longitude] = watch(["latitude", "longitude"]);

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                setValue("latitude", position.coords.latitude);
                setValue("longitude", position.coords.longitude);
            });
        } else {
            alert("Sorry, your browser does not support geolocation.");
        }
    }, [setValue]);

    const onSubmit = useCallback(
        async (data) => {
            setIsLoading(true);
            try {
                await request("/safetipinapi", "POST", data, true);
                alert("Form submitted successfully");
            } catch (e) {
                alert("You are offline, we will sync your data when you are online.");
            }
            localStorage.removeItem(`form_id:${id}`);
            reset({
                ...defaultData,
                latitude,
                longitude,
            });
            setIsLoading(false);
        },
        [id, reset, latitude, longitude, defaultData, setIsLoading]
    );

    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <QuestionsFields questions={questions} />
                <span className="text-danger mr-2">*</span>
                <span className="remark"> - Required Field</span>
                <div className="text-center">
                    <button type="submit" className="submit">
                        Submit
                    </button>
                </div>
            </form>
            <StoreLocalValue />
        </FormProvider>
    );
};

export default memo(QuestionsForm);
