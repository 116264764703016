import React, { memo, useCallback } from "react";
import { useController, useFormContext } from "react-hook-form";
import { useModalActions, useOnline } from "../ModalActionsContext";

const Map = ({ index, question, compulsary, field }) => {
  const baseName = `${field}[${index}]`;
  const answerName = `${baseName}.answer`;
  const { setLocationState } = useModalActions();
  const isOnline = useOnline();
  const required = compulsary === "1";
  const { setValue } = useFormContext();
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({
    name: answerName,
    rules: {
      required:  false,
    },
  });

  const handleClick = useCallback(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords;
        setValue("latitude", latitude);
        setValue("longitude", longitude);
        onChange(`${latitude},${longitude}`);
        setLocationState({
          latitude,
          longitude,
          answerName,
          setValue,
        });
      });
    } else {
      alert("Sorry, your browser does not support geolocation.");
    }
  }, [setValue, setLocationState, onChange, answerName]);
  return (
    <>
      <div className="headingadj col2">
        <div style={{ width: 25, float: "left" }}>
          <b className="num_heading">{index + 1}- </b>
        </div>
        <div style={{ width: "90%", float: "left" }}>
          {question}
          {required && <span className="text-danger ml-2">*</span>}
        </div>
      </div>
      <p className="fieldadj col3">
        {isOnline && (
          <button
            type="button"
            className={value ? "btnclasss" : "btnclass"}
            onClick={handleClick}
          >
            Get Address
          </button>
        )}
        {value && <img src="/img/check.png" alt="check" width="30px" />}
      </p>
      {error && (
        <div className="text-danger float-left w-100 p-2">{error.message}</div>
      )}
    </>
  );
};

export default memo(Map);
