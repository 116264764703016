const buildFormData = (data) => {
  if (!data) {
    return null;
  }
  const formData = new FormData();
  Object.keys(data).forEach((key) => {
    formData.append(
      key,
      Array.isArray(data[key]) ? JSON.stringify(data[key]) : data[key]
    );
  });
  return formData;
};

const request = async (url, method, data, json = false) => {
  const fetchedData = await fetch(
    `${process.env.REACT_APP_URL}api${url}`,
    {
      method,
      body: json ? JSON.stringify(data) : buildFormData(data),
    }
  );

  if (json) {
    return fetchedData;
  } else {
    const response = await fetchedData.json();
    return response;
  }
};

export default request;
