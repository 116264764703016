import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Login from "./page/Login";
import List from "./page/List";
import Questions from "./page/Questions";

const Router = () => {
  return (
    <Routes>
      <Route exact path="/" element={<Navigate to="/userlogin" />} />
      <Route path="userlogin" element={<Login />} />
      <Route path="userlogin/list" element={<List />} />
      <Route path="userlogin/:id" element={<Questions />} />
    </Routes>
  );
};

export default Router;
