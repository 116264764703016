import React, { memo } from "react";
import { useController } from "react-hook-form";
import cn from "classnames";
import UploadButton from "./UploadButton";
import ImageView from "../ImageView";

const ShortAnswer = ({
  question,
  index,
  compulsary,
  photo_required,
  field: name,
}) => {
  const baseName = `${name}[${index}]`;
  const required = compulsary === "1";
  const {
    field,
    fieldState: { error },
  } = useController({
    name: `${baseName}.answer`,
    rules: {
      required: required ? "Please enter your answer" : false,
    },
  });
  const isUploadVisible = photo_required === "1";

  return (
    <div className="formdiv">
      <div className={cn("headingadj", isUploadVisible && "col2")}>
        <div style={{ width: 25, float: "left" }}>
          <b className="num_heading">{index + 1}- </b>
        </div>
        <div style={{ width: "90%", float: "left" }}>
          {question}
          {required && <span className="text-danger ml-2">*</span>}
        </div>
      </div>
      {isUploadVisible && (
        <p className="fieldadj col3">
          <UploadButton baseName={`${field}[${index}]`} />
        </p>
      )}
      <div className="adjust">
        <p className="fieldadj">
          <input
            {...field}
            type="text"
            className="inputadj"
            placeholder="Your Answer "
          />
        </p>
        {error && <div className="text-danger p-2">{error.message}</div>}
      </div>
      <ImageView name={`${baseName}.image`} />
    </div>
  );
};

export default memo(ShortAnswer);
