import React, { useEffect, memo, useState, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import db from "../../api/db";
import request from "../../api/request";
import CaptureImage from "./CaptureImage";
import LocationModal from "./LocationModal";
import { ModalActions, Online } from "./ModalActionsContext";
import QuestionsForm from "./QuestionsForm";

const Questions = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [questions, setQuestions] = useState(null);
  const [questionsData, setQuestionsData] = useState([]);
  const [formName, setFormName] = useState("");
  const [imageState, setImageState] = useState(null);
  const [locationState, setLocationState] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isOnline, setIsOnline] = useState(true);
  const userid = localStorage.getItem("userid");

  useEffect(() => {
    const isOnline = () => setIsOnline(true);
    const isOffline = () => setIsOnline(false);
    window.addEventListener("online", isOnline, false);
    window.addEventListener("offline", isOffline, false);

    return () => {
      window.removeEventListener("online", isOnline, false);
      window.removeEventListener("offline", isOffline, false);
    };
  }, []);

  useEffect(() => {
    if (!userid) {
      navigate("../");
    }
    console.log(id, userid);

    request(
      `/form?${new URLSearchParams({
        form_id: id,
      }).toString()}`
    ).then(async ({ form, form_name }) => {
        const all = await db.collection('saftipin-form-data').get()
    //   db.collection('saftipin-form-data').get().then(data => {
        setQuestionsData(all);
        setQuestions(form);
        setFormName(form_name);
    //   })
    });
  }, [navigate, id, userid]);

  const actions = useMemo(
    () => ({
      setImageState,
      setLocationState,
      setIsLoading,
    }),
    []
  );

  const handleLogout = () => {
    localStorage.removeItem("userid");
    navigate("/userlogin");
  };
  console.log({ questionsData, questions });

  return (
    <ModalActions.Provider value={actions}>
      <div className="wrapper">
        <div className="wrapper_inner">
          <div className="content" style={{ minHeight: "100vh" }}>
            <div className="content_inner">
              <div className="container">
                <div className="row">
                  <div className="col-6">
                    <img
                      src="/img/SafetiPin-Logo-1.png"
                      alt="logo"
                      style={{ width: 75 }}
                    />
                  </div>
                  <div className="col-6 text-right">
                    <button className="btnclass" onClick={handleLogout}>
                      Logout
                    </button>
                  </div>
                </div>
              </div>
              <div className="text-danger text-right pr-4">
                {!isOnline && "You are offline."}
              </div>
              <div className="container">
                <div
                  className="formdiv"
                  style={{ borderTop: "10px solid #029cb4", marginTop: 5 }}
                >
                  <p className="headingadj" style={{ paddingBottom: 0 }}>
                    <span className="heading1">{formName}</span>
                  </p>
                </div>
                <Online.Provider value={isOnline}>
                  {questions && (
                    <QuestionsForm
                      id={id}
                      userid={userid}
                      questions={questions}
                      questionsData={questionsData}
                    />
                  )}
                </Online.Provider>
              </div>
            </div>
          </div>
        </div>
        {imageState && (
          <CaptureImage
            {...imageState}
            handleClose={() => setImageState(null)}
          />
        )}
        {locationState && (
          <LocationModal
            {...locationState}
            handleClose={() => setLocationState(null)}
          />
        )}
      </div>
      {isLoading && (
        <div className="loader-hourglass">
          <div className="lds-hourglass"></div>
        </div>
      )}
    </ModalActions.Provider>
  );
};

export default memo(Questions);
