import React, { memo } from "react";
import Checkboxes from "./components/Checkboxes";
import Likert from "./components/Likert";
import Map from "./components/Map";
import MultipleChoice from "./components/MultipleChoice";
import Paragraph from "./components/Paragraph";
import ShortAnswer from "./components/ShortAnswer";

const typeMappings = {
  "Multiple Choice": MultipleChoice,
  "Multiple Choice with two colomn": MultipleChoice,
  "Short Answer": ShortAnswer,
  Paragraph: Paragraph,
  Checkboxes: Checkboxes,
  "Checkboxes with two colomn": Checkboxes,
  Map: Map,
  Likert: Likert,
};

const QuestionsFields = ({ questions }) => {
  return questions.map((question, index) => {
    const Component = typeMappings[question.type];

    return (
      <div className="formdiv" key={question.question_id}>
        {Component && <Component {...question} index={index} field="data" />}
      </div>
    );
  });
};

export default memo(QuestionsFields);
