import React, { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import request from "../../api/request";

const Login = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm();

  useEffect(() => {
    const userid = localStorage.getItem("userid");
    if (userid) {
      navigate("list", { replace: true });
    }
  }, [navigate])

  const onSubmit = useCallback(
    async (data) => {
      const { userid, message } = await request("/user", "POST", {
        ...data,
        action: "Login Check",
      });

      if (userid) {
        localStorage.setItem("userid", userid);
        navigate("list", { replace: true });
      } else {
        setError('password', {
          type: 'custom',
          message
        });
      }
    },
    [navigate]
  );

  return (
    <div className="wrapper">
      <div className="wrapper_inner">
        <div
          className="container"
          style={{ minHeight: "100vh", maxWidth: "100vw" }}
        >
          <div
            className="mb-2"
            style={{
              minHeight: 75,
              maxWidth: 100,
              margin: "auto",
              paddingTop: 15,
            }}
          >
            <img src="/img/SafetiPin-Logo-1.png" alt="logo" />
          </div>
          <div className="default_template_holder clearfix">
            <div className="contact_detail">
              <div className="two_columns_33_66 clearfix grid2">
                <div className="row">
                  <div className="col-md-4 offset-md-4">
                    <form
                      className="contact_form"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <p className="text-center">
                        <b style={{ fontSize: "24px" }}>User Login</b>
                      </p>

                      <div
                        className="two_columns_50_50 clearfix"
                        style={{ height: 10 }}
                      ></div>
                      <div className="two_columns_50_50 clearfix">
                        <label>
                          <h5>User Name</h5>
                        </label>
                        <input
                          type="text"
                          {...register("username", {
                            required: "Please enter user name",
                          })}
                        />
                      </div>
                      <div className="two_columns_50_50 clearfix">
                        <label>
                          <h5>Password</h5>
                        </label>
                        <input
                          type="password"
                          {...register("password", {
                            required: "Please enter password",
                          })}
                        />
                        <span id="error_password" className="error">
                          {errors.password?.message}
                        </span>
                      </div>
                      <div className="two_columns_50_50 clearfix text-center">
                        <button
                          type="submit"
                          className="qbutton contact_form_button"
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
