import { useEffect, memo, useRef } from "react";
import isEqual from "lodash/isEqual";
import cloneDeep from "lodash/cloneDeep";
import { useFormContext } from "react-hook-form";
import db from "../../api/db";

const StoreLocalValue = () => {
    const dataRef = useRef();
    const { watch, } = useFormContext();
    const { data, ...rest } = watch();

    useEffect(() => {
        if(!isEqual(data, dataRef.current)) {
            console.log('aewf', data, dataRef.current);
            dataRef.current = cloneDeep(data);
            localStorage.setItem('saftipin-form-data', JSON.stringify(rest));
            db.collection('saftipin-form-data').set(data)
        }
    });

    return null;
};

export default memo(StoreLocalValue);
