import React, { memo } from "react";
import { useController } from "react-hook-form";
import useEvent from "../../../hooks/useEvent";
import ImageView from "../ImageView";
import UploadButton from "./UploadButton";

const Likert = ({
  question,
  index,
  value: choicesString,
  compulsary,
  photo_required,
  field,
  type,
  likert_col_heading,
}) => {
  const headings = likert_col_heading.split(";");
  const baseName = `${field}[${index}]`;
  const required = compulsary === "1";
  const {
    field: { onChange, value = "" },
    fieldState: { error },
  } = useController({
    name: `${baseName}.answer`,
    rules: {
      required: required ? "Please select all options" : false,
      validate: (data) => data.split(",").some((v) => v === "") ? 'There is an empty option' : true,
    },
  });
  const choices = choicesString.split(";");
  const isUploadVisible = photo_required === "1";

  const handleChange = useEvent((event) => {
    const {
      value: newVlaue,
      dataset: { index },
    } = event.target;
    onChange(
      headings
        .map((_, i) =>
          index === "" + i ? newVlaue : value.split(",")[i] || ""
        )
        .join(",")
    );
  });

  return (
    <div className="formdiv">
      <div className="headingadj">
        <div style={{ width: 25, float: "left" }}>
          <b className="num_heading">{index + 1}- </b>
        </div>
        <div style={{ width: "90%", float: "left" }}>
          {question}
          {required && <span className="text-danger ml-2">*</span>}
        </div>
      </div>
      <div className="adjust">
        <table className="table table-bordered table-hover table-responsive">
          <thead>
            <tr>
              <td></td>
              {headings.map((heading) => (
                <td key={heading}>
                  <strong>{heading}</strong>
                </td>
              ))}
            </tr>
          </thead>
          <tbody>
            {choices.map((choice) => (
              <tr key={choice}>
                <td>
                  <strong>{choice}</strong>
                </td>
                {headings.map((heading, index) => (
                  <td key={heading}>
                    <input
                      type="radio"
                      className="radioadj"
                      value={choice}
                      onChange={handleChange}
                      checked={choice === value.split(",")[index]}
                      data-index={index}
                    />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        {error && <div className="text-danger p-2">{error.message}</div>}
        {isUploadVisible && (
          <p className="fieldadj float-left w-100">
            <UploadButton baseName={baseName} />
          </p>
        )}
      </div>
      <ImageView name={`${baseName}.image`} />
    </div>
  );
};

export default memo(Likert);
