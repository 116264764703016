import React, { memo, useEffect, useRef } from "react";

const RenderMap = ({ latitude, longitude, setValue, answerName }) => {
  const ref = useRef();

  useEffect(() => {
    const map = new window.google.maps.Map(ref.current, {
      center: new window.google.maps.LatLng(latitude, longitude),
      zoom: 12,
    });

    const vMarker = new window.google.maps.Marker({
      position: new window.google.maps.LatLng(latitude, longitude),
      draggable: true,
    });

    window.google.maps.event.addListener(vMarker, "dragend", function (evt) {
      const lat = evt.latLng.lat().toFixed(10);
      const lng = evt.latLng.lng().toFixed(10);
      setValue("latitude", lat);
      setValue("longitude", lng);
      setValue("answerName", `${lat},${lng}`);
      map.panTo(evt.latLng);
    });
    map.setCenter(vMarker.position);
    vMarker.setMap(map);
  }, [longitude, latitude, setValue, answerName]);

  return <div ref={ref} id="map" style={{ height: 500 }} />;
};

export default memo(RenderMap);
