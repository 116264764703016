import React, { useCallback, memo } from "react";
import cn from "classnames";
import { useController } from "react-hook-form";
import OtherComponent from "./OtherComponent";
import UploadButton from "./UploadButton";
import ImageView from "../ImageView";

const MultipleChoice = ({
  question,
  index,
  value: choicesString,
  compulsary,
  photo_required,
  field,
  type,
}) => {
  const baseName = `${field}[${index}]`;
  const required = compulsary === "1";
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({
    name: `${baseName}.answer`,
    rules: {
      required: required ? "Please select any option" : false,
    },
  });
  const choices = choicesString.split(";");
  const isSingle = type === "Multiple Choice";
  const isUploadVisible = photo_required === "1";

  const handleChange = useCallback(
    (event) => onChange(event.target.value),
    [onChange]
  );

  return (
    <div className="formdiv">
      <div className="headingadj">
        <div style={{ width: 25, float: "left" }}>
          <b className="num_heading">{index + 1}- </b>
        </div>
        <div style={{ width: "90%", float: "left" }}>
          {question}
          {required && <span className="text-danger ml-2">*</span>}
        </div>
      </div>
      <div className="adjust">
        {choices.map(
          (choice) => (
              <label
                className={cn("fieldadj1", isSingle ? "col1" : "col21")}
                key={choice}
              >
                <div className="div11st">
                  <input
                    type="radio"
                    className="radioadj"
                    value={choice}
                    onChange={handleChange}
                    checked={choice === value}
                  />
                </div>
                <div className="div22nd">{choice}</div>
              </label>
            )
        )}
        {error && <div className="text-danger float-left w-100 p-2">{error.message}</div>}
        {value.startsWith("Other") && (
          <OtherComponent
            className="inputadj rb_other"
            baseName={baseName}
            required={required}
          />
        )}
        {isUploadVisible && (
          <p className="fieldadj float-left w-100">
            <UploadButton baseName={baseName} />
          </p>
        )}
      </div>
      <ImageView name={`${baseName}.image`} />
    </div>
  );
};

export default memo(MultipleChoice);
