import React, { memo } from "react";
import { useFormContext } from "react-hook-form";
import useEvent from "../../../hooks/useEvent";
import { useModalActions } from "../ModalActionsContext";

const UploadButton = ({ baseName }) => {
  const { watch, setValue } = useFormContext();
  const { setImageState } = useModalActions();
  const handleUpload = useEvent(() => {
    setImageState({
      field: `${baseName}.image`,
      setValue,
      watch,
    });
  });

  return (
    <button type="button" className="btnclass" onClick={handleUpload}>
      Upload
    </button>
  );
};

export default memo(UploadButton);
