import React, { memo } from "react";
import { useFormContext } from "react-hook-form";
import useEvent from "../../hooks/useEvent";

const ImageView = ({ name }) => {
  const { watch, setValue } = useFormContext();
  const imageString = watch(name);
  const images = imageString ? imageString.split(",") : [];

  const handleDelete = useEvent((event) => {
    setValue(name, images.filter((_, index) => index !== Number(event.target.dataset.index)).join(","));
  })

  return (
    <div className="fieldadj">
      {images.map((image, index) => (
        <div className="float-left" key={image.substr(-15)}>
          <img
            className="imgadj"
            src={image.startsWith('/') ? `data:image/jpeg;base64,${image}` : `${process.env.REACT_APP_URL}uploads/${image}`}
            alt="upload"
          />
          <i className="fa fa-times-circle remove" data-index={index} onClick={handleDelete}></i>
        </div>
      ))}
    </div>
  );
};

export default memo(ImageView);
